const getHtml = (tableContent) => {
  return `
  <html>
    <head>
      <meta charset='utf-8'/>
      <style>body{margin:0;}table{width:100%;border-collapse:collapse;font-family: 新宋体;}tr.border th,tbody td{border:thin solid #000000;}</style>
    </head>
    <body>${tableContent}</body>
  </html>`;
}

const titleStyle = "text-align:center;font-weight: normal;height:2em; font-size:16px;"
const contentStyle = "text-align:center;height:2em; font-size:16px;"

export function utilExport(params, orderstatus) {
  let tableContent = `<table>`;
  tableContent += `<thead>
  <tr class="border">${['序号', '省级合伙人', '申领时间', '申领单号', '设备总金额', '状态'].map(t => `<th style="${titleStyle}">${t}</th>`).join('')}</tr></thead><tbody>`;
  params.forEach((t, i) => { tableContent += `<tr>${[i + 1, t.txt_realname, t.dt_order, t.txt_order_number, t.dec_order_amount, orderstatus == "0" ? '未确认' : '已确认'].map(t => `<td style="${contentStyle}">${t}</td>`).join('')}</tr>`; })
  tableContent += '</tbody></table>';
  var blob = new Blob([getHtml(tableContent)], { type: "text/html" });
  var a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.download = "设备申领.xls";
  a.click()
  a.remove()
}


export function deviceStatisExport(params) {
  let tableContent = `<table>`;
  tableContent += `<thead>
  <tr class="border">${['序号', '设备类型', '设备唯一识别码', '所在地区', '已缴纳使用金', '缴纳日期', '返还使用金金额', '待返还使用金金额'].map(t => `<th style="${titleStyle}">${t}</th>`).join('')}</tr></thead><tbody>`;
  params.forEach((t, i) => { tableContent += `<tr>${[i + 1, t.txt_type_name, t.txt_device_iot, t.txt_area_name, t.dec_agent_amount == null ? '- -' : '￥' + t.dec_agent_amount, t.dt_agent == null ? '- -' : t.dt_agent, t.sum_returned_amount == null ? '- -' : '￥' + t.sum_returned_amount, t.sum_to_be_return_amount == null ? '- -' : '￥' + t.sum_to_be_return_amount].map(t => `<td style="${contentStyle}">${t}</td>`).join('')}</tr>`; })
  // 合计行
  let summaryLine = `<tr><td style="${contentStyle}">合计</td><td></td><td></td><td></td><td style="${contentStyle}">￥${params.map(t => parseFloat(t.dec_agent_amount || 0)).reduce((a, b) => a + b, 0).toFixed(2)}</td>`;
  summaryLine += `<td></td><td style="${contentStyle}">￥${params.map(t => parseFloat(t.sum_returned_amount || 0)).reduce((a, b) => a + b, 0).toFixed(2)}</td>`;
  summaryLine += `<td style="${contentStyle}">￥${params.map(t => parseFloat(t.sum_to_be_return_amount || 0)).reduce((a, b) => a + b, 0).toFixed(2)}</td>`;
  tableContent += summaryLine + '</tr></tbody></table>';
  var blob = new Blob([getHtml(tableContent)], { type: "text/html" });
  var a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.download = "设备列表.xls";
  a.click()
  a.remove()
}

export function orderStatisExport(params) {
  let tableContent = `<table>`;
  tableContent += `<thead>
  <tr class="border">${['序号', '设备唯一识别码', '收款金额', '收款日期', '省代分配金额', '市代分配金额', '业务员分配金额', '诊所分配金额', '返还使用金', '我方留存金额'].map(t => `<th style="${titleStyle}">${t}</th>`).join('')}</tr></thead><tbody>`;
  params.forEach((t, i) => { tableContent += `<tr>${[i + 1, t.txt_device_iot, t.dec_order_amount == null ? '- -' : '￥' + t.dec_order_amount, t.payDatetime, t.dec_province_partner_split_amount == null ? '- -' : '￥' + t.dec_province_partner_split_amount, t.dec_city_partner_split_amount == null ? '- -' : '￥' + t.dec_city_partner_split_amount, t.dec_salesman_split_amount == null ? '- -' : '￥' + t.dec_salesman_split_amount, t.dec_clinic_split_amount == null ? '- -' : '￥' + t.dec_clinic_split_amount, t.dec_return_amount == null ? '- -' : '￥' + t.dec_return_amount, t.dec_profit_amount == null ? '- -' : '￥' + t.dec_profit_amount].map(t => `<td style="${contentStyle}">${t}</td>`).join('')}</tr>`; })
  // 合计行
  let summaryLine = `<tr><td style="${contentStyle}">合计</td><td></td><td style="${contentStyle}">￥${params.map(t => parseFloat(t.dec_order_amount || 0)).reduce((a, b) => a + b, 0).toFixed(2)}</td>`;
  summaryLine += `<td></td><td style="${contentStyle}">￥${params.map(t => parseFloat(t.dec_province_partner_split_amount || 0)).reduce((a, b) => a + b, 0).toFixed(2)}</td>`;
  summaryLine += `<td style="${contentStyle}">￥${params.map(t => parseFloat(t.dec_city_partner_split_amount || 0)).reduce((a, b) => a + b, 0).toFixed(2)}</td>`;
  summaryLine += `<td style="${contentStyle}">￥${params.map(t => parseFloat(t.dec_salesman_split_amount || 0)).reduce((a, b) => a + b, 0).toFixed(2)}</td>`;
  summaryLine += `<td style="${contentStyle}">￥${params.map(t => parseFloat(t.dec_clinic_split_amount || 0)).reduce((a, b) => a + b, 0).toFixed(2)}</td>`;
  summaryLine += `<td style="${contentStyle}">￥${params.map(t => parseFloat(t.dec_return_amount || 0)).reduce((a, b) => a + b, 0).toFixed(2)}</td>`;
  summaryLine += `<td style="${contentStyle}">￥${params.map(t => parseFloat(t.dec_profit_amount || 0)).reduce((a, b) => a + b, 0).toFixed(2)}</td>`;
  tableContent += summaryLine + '</tr></tbody></table>';
  tableContent += '</tbody></table>';
  var blob = new Blob([getHtml(tableContent)], { type: "text/html" });
  var a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.download = "订单列表.xls";
  a.click()
  a.remove()
}

export function exportToCsv(params) {
  let result = "序号,诊所名称,所在地,设备名称,至今检测次数,终端收费（元）,轻盈分成收入（元）\n";
  let trs = "";
  for (let i = 0; i < params.length; i++) {
    trs += i+1 + "," + params[i].txt_clinic_name + "," + params[i].txt_area_name + "," + params[i].txt_type_name + "," + params[i].cnt_order + ",￥" + (params[i].sum_order_amount||0.00) + ",￥" + (params[i].sum_profit_amount||0.00) + "\n";
  }
  let summaryLine = "合计,,,," + params.map(t=> parseFloat(t.cnt_order||0)).reduce((a, b) => a + b, 0).toFixed(2) + "," + params.map(t=> parseFloat(t.sum_order_amount||0)).reduce((a, b) => a + b, 0).toFixed(2) + "," + params.map(t=> parseFloat(t.sum_profit_amount||0)).reduce((a, b) => a + b, 0).toFixed(2)
  result += trs;
  result += summaryLine;

  result = "\ufeff" + result;
  var blob = new Blob([result], {type: "text/csv"});
  var a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.download = "诊所统计.csv";
  a.click()
  a.remove()
}


// 设备绑定明细 导出
export function deviceBindDetailExport(params, total) {
  let tableContent = `<table>`;
  tableContent += `<thead>
  <tr class="border">${['序号', '设备唯一识别码', '绑定时间', '设备类型', '省份', '省代', '城市', '市代', '业务员', '诊所医生', '诊所'].map(t => `<th style="${titleStyle}">${t}</th>`).join('')}</tr></thead><tbody>`;
  params.forEach((t, i) => { tableContent += `<tr>${[i + 1, t.txt_device_iot, t.dt_clinic_agent, t.txt_type_name, t.txt_province_name, t.txt_province_partner_name, t.txt_city_name, t.txt_city_partner_name, t.txt_salesman_name, t.txt_clinic_agent_name, t.txt_clinic_name ].map(t => `<td style="${contentStyle}">${t}</td>`).join('')}</tr>`; })
  // 合计行
  let summaryLine = `<tr><td style="${contentStyle}">合计</td><td style="${contentStyle}">${total}</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>`;
  tableContent += summaryLine + '</tr></tbody></table>';
  var blob = new Blob([getHtml(tableContent)], { type: "text/html" });
  var a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.download = "设备绑定明细.xls";
  a.click()
  a.remove()
}

// 订单排行 导出
export function exportForOrders(params) {
  let tableContent = `<table>`;
  tableContent += `<thead>
  <tr class="border">${['序号', '日期', '订单数', 'IOT', '省份', '省代', '城市', '市代', '业务员', '诊所医生', '诊所'].map(t => `<th style="${titleStyle}">${t}</th>`).join('')}</tr></thead><tbody>`;
  params.forEach((t, i) => { tableContent += `<tr>${[i + 1, t.dt_date, t.cnt_orders, t.txt_device_iot, t.txt_province_name, t.txt_province_partner_name, t.txt_city_name, t.txt_city_partner_name, t.txt_salesman_name, t.txt_clinic_agent_name, t.txt_clinic_name ].map(t => `<td style="${contentStyle}">${t}</td>`).join('')}</tr>`; })
  // 合计行
  // let summaryLine = `<tr><td style="${contentStyle}">合计</td><td style="${contentStyle}">${total}</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>`;
  tableContent += '</tbody></table>';
  var blob = new Blob([getHtml(tableContent)], { type: "text/html" });
  var a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.download = "订单排行.xls";
  a.click()
  a.remove()
}

// 诊所负责人 导出
export function exportClinicList(params) {
  let tableContent = `<table>`;
  tableContent += `<thead>
  <tr class="border">${['序号', '姓名', '预留手机号', '代理地区', '上级代理', '审核状态', '申请时间', '合同状态', '诊所是否签署', '省级是否签署', '合同发送时间', '合同生效时间'].map(t => `<th style="${titleStyle}">${t}</th>`).join('')}</tr></thead><tbody>`;
  params.forEach((t, i) => { tableContent += `<tr>${[i + 1, t.txt_realname, t.txt_mobile, t.txt_area_name, t.txt_pname, t.txt_verify_status==1?'已通过':t.txt_verify_status==2?'未通过':"待审核", t.dt_apply||'- -', t.txt_status=='-1'?'未发送':t.txt_status==1?'未生效':t.txt_status==2?'已失效':t.txt_status==3?'已生效':'已过期', t.t_level4_status==1?'未签署':'已签署', t.t_level1_status==1?'未签署':'已签署', t.dt_contract_create||'- -', t.dt_effect||'- -' ].map(t => `<td style="${contentStyle}">${t}</td>`).join('')}</tr>`; })
  tableContent += '</tbody></table>';
  var blob = new Blob([getHtml(tableContent)], { type: "text/html" });
  var a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.download = "诊所负责人.xls";
  a.click()
  a.remove()
}

// 省代 导出
export function exportProList(params) {
  let tableContent = `<table>`;
  tableContent += `<thead>
  <tr class="border">${['序号', '姓名', '预留手机号', '代理地区', '审核状态', '申请时间'].map(t => `<th style="${titleStyle}">${t}</th>`).join('')}</tr></thead><tbody>`;
  params.forEach((t, i) => { tableContent += `<tr>${[i + 1, t.txt_realname, t.txt_mobile, t.txt_area_name, t.txt_verify_status==1?'已通过':t.txt_verify_status==2?'未通过':"待审核", t.dt_apply||'- -'].map(t => `<td style="${contentStyle}">${t}</td>`).join('')}</tr>`; })
  tableContent += '</tbody></table>';
  var blob = new Blob([getHtml(tableContent)], { type: "text/html" });
  var a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.download = "省级合伙人.xls";
  a.click()
  a.remove()
}

// 省代、市代、业务员 导出
export function exportAgentList(params, name) {
  let tableContent = `<table>`;
  tableContent += `<thead>
  <tr class="border">${['序号', '姓名', '预留手机号', '代理地区', '上级代理', '审核状态', '申请时间'].map(t => `<th style="${titleStyle}">${t}</th>`).join('')}</tr></thead><tbody>`;
  params.forEach((t, i) => { tableContent += `<tr>${[i + 1, t.txt_realname, t.txt_mobile, t.txt_area_name, t.txt_pname, t.txt_verify_status==1?'已通过':t.txt_verify_status==2?'未通过':"待审核", t.dt_apply||'- -'].map(t => `<td style="${contentStyle}">${t}</td>`).join('')}</tr>`; })
  tableContent += '</tbody></table>';
  var blob = new Blob([getHtml(tableContent)], { type: "text/html" });
  var a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.download = name + ".xls";
  a.click()
  a.remove()
}
