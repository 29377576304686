<template>
<div id="page" class="equipment-statis-and-export row-start-start">
  <div class="esae-left">
    <el-row class="csae-form-line row-start-between">
      <el-form :model="form" class="row-center-start" ref="formRef">
        <el-row>
          <el-form-item label="截止日期" prop="dateRange">
            <el-date-picker value-format="YYYY-MM-DD" :disabled-date="disabledDate" v-model="form.dateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
          </el-form-item>
          <el-form-item label="设备类型">
            <el-select v-model="form.txt_type_code" size="small">
              <el-option v-for="item in deviceTypeOption" :key="item.t_device_type_id" :label="item.txt_type_name" :value="item.txt_type_code" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getDeviceData()">加载数据</el-button>
            <el-button type="info" @click="reset()">重置</el-button>
            <el-button type="primary" :disabled="(table.deviceTData||[]).length==0" @click="exportTo('device')">导出到Excel</el-button>
          </el-form-item>
        </el-row>
      </el-form>
    </el-row>
    <el-table :data="table.deviceTData" show-summary sum-text="合计" :row-class-name="getRowClass" @row-click="getOrderData" v-loading="table.deviceTLoading">
      <el-table-column prop="txt_type_name" label="设备类型" align="center" />
      <el-table-column prop="txt_device_iot" label="设备唯一识别码" align="center" />
      <el-table-column prop="txt_area_name" label="所在地区" align="center" />
      <el-table-column prop="dec_agent_amount" label="已缴纳使用金" align="center">
        <template #default="scope">
          <template v-if="scope.row.dec_agent_amount">￥{{scope.row.dec_agent_amount}}</template>
          <template v-else>- -</template>
        </template>
      </el-table-column>
      <el-table-column prop="dt_agent" label="缴纳日期" align="center" />
      <el-table-column prop="sum_returned_amount" label="返还使用金金额" align="center">
        <template #default="scope">
          <template v-if="scope.row.sum_returned_amount">￥{{scope.row.sum_returned_amount}}</template>
          <template v-else>- -</template>
        </template>
      </el-table-column>
      <el-table-column prop="sum_to_be_return_amount" label="待返还使用金金额" align="center">
        <template #default="scope">
          <template v-if="scope.row.sum_to_be_return_amount">￥{{scope.row.sum_to_be_return_amount}}</template>
          <template v-else>- -</template>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div class="esae-right">
    <el-row class="csae-form-line row-start">
      <el-button type="primary" @click="getOrderData(null)">加载全部数据</el-button>
      <el-button type="info" :disabled="(table.orderTData||[]).length==0" @click="exportTo('order')">导出到Excel</el-button>
    </el-row>
    <el-table :data="table.orderTData" show-summary sum-text="合计" v-loading="table.orderTLoading">
      <el-table-column prop="txt_device_iot" label="设备唯一识别码" align="center" />
      <el-table-column prop="dec_order_amount" label="收款金额" align="center">
        <template #default="scope">
          <template v-if="scope.row.dec_order_amount">￥{{scope.row.dec_order_amount}}</template>
          <template v-else>- -</template>
        </template>
      </el-table-column>
      <el-table-column prop="payDatetime" label="收款日期" align="center" />
      <el-table-column prop="dec_province_partner_split_amount" label="省代分配金额" align="center">
        <template #default="scope">
          <template v-if="scope.row.dec_province_partner_split_amount">￥{{scope.row.dec_province_partner_split_amount}}</template>
          <template v-else>- -</template>
        </template>
      </el-table-column>
      <el-table-column prop="dec_city_partner_split_amount" label="市代分配金额" align="center">
        <template #default="scope">
          <template v-if="scope.row.dec_city_partner_split_amount">￥{{scope.row.dec_city_partner_split_amount}}</template>
          <template v-else>- -</template>
        </template>
      </el-table-column>
      <el-table-column prop="dec_salesman_split_amount" label="业务员分配金额" align="center">
        <template #default="scope">
          <template v-if="scope.row.dec_salesman_split_amount">￥{{scope.row.dec_salesman_split_amount}}</template>
          <template v-else>- -</template>
        </template>
      </el-table-column>
      <el-table-column prop="dec_clinic_split_amount" label="诊所分配金额" align="center">
        <template #default="scope">
          <template v-if="scope.row.dec_clinic_split_amount">￥{{scope.row.dec_clinic_split_amount}}</template>
          <template v-else>- -</template>
        </template>
      </el-table-column>
      <el-table-column prop="dec_return_amount" label="返还使用金" align="center">
        <template #default="scope">
          <template v-if="scope.row.dec_return_amount">￥{{scope.row.dec_return_amount}}</template>
          <template v-else>- -</template>
        </template>
      </el-table-column>
      <el-table-column prop="dec_profit_amount" label="我方留存金额" align="center">
        <template #default="scope">
          <template v-if="scope.row.dec_profit_amount">￥{{scope.row.dec_profit_amount}}</template>
          <template v-else>- -</template>
        </template>
      </el-table-column>
    </el-table>
  </div>
</div>
</template>

<script>
// 财务管理 - 设备统计
import { ElMessage, } from 'element-plus';
// getCurrentInstance, 
import { onMounted, onBeforeMount, reactive, toRefs } from 'vue';
import { deviceStatisExport, orderStatisExport } from "assets/js/export";
import { getDeviceType, deviceStatis, getDeviceCashflow, getSystemTime } from "api/apis.js";
export default {
  setup() {
    // const instance = getCurrentInstance();
    // let {ctx:that, proxy} = getCurrentInstance();
    const state = reactive({
      form: {
        dateRange: [],
        txt_type_code: ""
      },
      table: {
        deviceTData: [],
        deviceTLoading: false,
        orderTData: [],
        orderTLoading: false,
      },
      deviceTypeOption: [],
      dateRange: []
    });

    onBeforeMount(() => {
      getDate();
    })

    onMounted(() => {
      getDevices();
      getDeviceData();
    });

    // 获取系统日期
    const getDate = () => {
      getSystemTime().then(response => {
        if (response.code == 200) {
          state.dateRange = [response.data.dt_min, response.data.dt_max];
        }
      })
    };

    // 导出
    const exportTo = (type) => {
      if (type == "device") {
        deviceStatisExport(state.table.deviceTData);
      } else {
        orderStatisExport(state.table.orderTData);
      }
    };

    // 禁止选择的日期
    const disabledDate = (time) => {
      if ((state.dateRange || []).length != 0) {
        return time.getTime() > Date.now() || time.getTime() < new Date(state.dateRange[0]).getTime();
      } else {
        return time.getTime() > Date.now();
      }
    };

    // 根据IOT获取订单配置
    const getOrderData = (row) => {
      if (row != null) {
        row.focus = true;
        let lastDeviceStatisFocusRow = JSON.parse(localStorage.getItem("lastDeviceStatisFocusRow"));
        if (lastDeviceStatisFocusRow && lastDeviceStatisFocusRow.txt_device_iot != row.txt_device_iot) {
          let tempIndex = state.table.deviceTData.findIndex(t => t.txt_device_iot == lastDeviceStatisFocusRow.txt_device_iot);
          if (tempIndex != -1) {
            state.table.deviceTData[tempIndex].focus = false;
          }
        }
        localStorage.setItem("lastDeviceStatisFocusRow", JSON.stringify(row));
      } else {
        state.table.deviceTData.forEach(item => item.focus = false)
      }
      // that.$forceUpdate();
      // console.log(proxy)
      let param = row ? { txt_device_iot: row.txt_device_iot } : null;
      state.table.orderTLoading = true;
      getDeviceCashflow(param).then(response => {
        if (response.code == 200) {
          state.table.orderTData = response.data;
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.orderTLoading = false;
      })
    };

    // 设置 省代 表格 行样式
    const getRowClass = ({ row }) => {
      if (row.focus) {
        return "eqSta-table-row-class";
      } else {
        return "";
      }
    };

    // 获取设备统计数据
    const getDeviceData = () => {
      state.table.deviceTLoading = true;
      // 请求参数
      let param = {
        dt_from: (state.form.dateRange || []).length > 0 ? state.form.dateRange[0] : "",
        dt_to: (state.form.dateRange || []).length > 0 ? state.form.dateRange[1] : "",
        device_type_code: state.form.txt_type_code
      };
      // 去掉无用请求参数
      for (let i = 0; i < Object.keys(param).length; i++) {
        let key = Object.keys(param)[i];
        if (param[key] == "") {
          i--;
          delete param[key];
        }
      }
      // 调用接口
      deviceStatis(param).then(response => {
        if (response.code == 200) {
          if (response.data) {
            state.table.deviceTData = response.data;
          } else {
            state.table.deviceTData = [];
          }
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.deviceTLoading = false;
      })
    };

    // 重置表单
    const reset = () => {
      state.form = {
        dateRange: [],
        txt_type_code: ""
      };
      getDeviceData();
    };

    // 获取设备类型
    const getDevices = () => {
      getDeviceType().then(response => {
        if (response.code == 200) {
          state.deviceTypeOption = response.data || [];
        } else {
          state.deviceTypeOption = [];
        }
      }).catch(e => {
        console.error(e);
        ElMessage.error(e);
      })
    };

    return {
      getDevices,
      ...toRefs(state),
      getDeviceData,
      reset,
      getOrderData,
      getRowClass,
      exportTo,
      getDate,
      disabledDate
    }
  }
}
</script>

<style lang="scss">
.equipment-statis-and-export {
  .esae-left {
    height: 100%;
    width: 45%;
    padding-right: 6px;
    box-sizing: border-box;
    border-right: 1px solid #ebeef5;

    .el-table {
      height: calc(100% - 150px);
      overflow: visible;

      tbody {
        .eqSta-table-row-class:hover>td {
          background-color: #89c3f0 !important;
        }
      }

      .el-table__row {
        cursor: pointer;

        &.eqSta-table-row-class {
          background-color: #89c3f0 !important;
        }
      }
    }
  }

  .esae-right {
    height: 100%;
    padding-left: 6px;
    box-sizing: border-box;
    width: calc(55% - 1px);

    .el-table {
      height: calc(100% - 80px);
      overflow: visible;
    }
  }
}
</style>
